import * as React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";

import "../styles/main.sass";

const AboutPage = ({ data }) => {
	const { nodes } = data.allImageSharp;

	const background = nodes.find(
		(node) => node.fluid.originalName === "desk.jpg"
	);
	const backgroundSrc = background.fluid.src;
	const backgroundStyle = {
		backgroundImage: `url('${backgroundSrc}`,
		backgroundSize: `cover`,
		backgroundAttachment: `fixed`,
	};

	const portrait = nodes.find(
		(el) => el.resize.originalName === "Pivnick_Rob.jpg"
	);

	return (
		<Layout>
			<div className="hero is-medium" style={backgroundStyle}>
				<div className="hero-body">
					<div className="container">
						<div className="columns">
							<div className="column is-half home-content">
								<img src={portrait.resize.src} width="auto" alt="Rob Pivnick" />
							</div>
							<div
								className="column is-half"
								style={{
									display: `flex`,
									flexDirection: `column`,
									justifyContent: `center`,
								}}
							>
								<div>
									<h1 className="title">Meet the author, Rob Pivnick.</h1>
								</div>
								<div className="mt-6">
									<p style={{ fontSize: `1.4em` }}>
										“I am simply a parent who cares about the level of financial
										knowledge of his children. I want to make sure that each of
										them develops good habits as early as possible with respect
										to saving, investing and an understanding of basic financial
										topics."
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="callout">
				<div className="container">
					<p className="paragraph">
						Rob Pivnick is an investor, entrepreneur, attorney, residential real
						estate investor and financial literacy advocate. Rob has a B.A. in
						economics from Emory University in Atlanta, GA and has both a law
						degree and an M.B.A. from SMU in Dallas, TX. Professionally, Rob
						runs his own law firm, and previously was General Counsel for a
						private real estate investment company, and has worked at an AmLaw
						200 law firm as well as in-house at Goldman, Sachs and Co.
					</p>
					<p className="paragraph">
						“I am simply a parent who cares about the level of financial
						knowledge of his children. I want to make sure that each of them
						develops good habits as early as possible with respect to saving,
						investing and an understanding of basic financial topics. I began
						this journey while writing an outline for my children on these
						topics. The outline turned into a few pages, which eventually turned
						into a full manuscript and a printed book. I will use it for all of
						my kids and will share it with non-profit organizations – if I can
						contribute to the financial literacy of our youths, I’ve done my
						part.
					</p>
					<p className="paragraph">
						As a country, we must increase our household savings rate from an
						anemic 4% to closer to average, so our residents are more
						financially literate, so less than 21% of adults think that the
						lottery is their ticket to retirement, so our youth rely less on
						debt, so more of our adults can answer basic financial literacy
						questions correctly . . . .”
					</p>
				</div>
			</div>
		</Layout>
	);
};

export default AboutPage;

export const query = graphql`
	query About {
		allImageSharp {
			nodes {
				resize {
					src
					width
					originalName
				}
				fluid(quality: 100, sizes: "1200,800") {
					srcSet
					src
					originalName
					sizes
				}
			}
		}
	}
`;
